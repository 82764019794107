
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class DevCSharp extends Vue {
        // Data --------------------------------------------------------------------------------------------------------
        private data() {
            return {
                braces1:
                    '{' + '\n' +
                    'if (someExpression)' + '\n' +
                    '{' + '\n' +
                    '    DoSomething();' + '\n' +
                    '}' + '\n' +
                    'else' + '\n' +
                    '{' + '\n' +
                    '    DoSomethingElse();' + '\n' +
                    '}' + '\n',

                braces2:
                    'switch (someExpression)' + '\n' +
                    '{' + '\n' +
                    'case 0:' + '\n' +
                    '    DoSomething();' + '\n' +
                    '    break;' + '\n' +
                    '' + '\n' +
                    'case 1:' + '\n' +
                    '    DoSomethingElse();' + '\n' +
                    '    break;' + '\n' +
                    '' + '\n' +
                    'case 2:' + '\n' +
                    '    {' + '\n' +
                    '        int n = 1;' + '\n' +
                    '        DoAnotherThing(n);' + '\n' +
                    '    }' + '\n' +
                    '    break;' + '\n' +
                    '}' + '\n',

                braces3:
                    'for (int index=0; index<100; index++)' + '\n' +
                    '{' + '\n' +
                    '    DoSomething(index);' + '\n' +
                    '}' + '\n',

                singleLineStatements1:
                    'public class Foo' + '\n' +
                    '{' + '\n' +
                    '    int bar;' + '\n' +
                    '' + '\n' +
                    '    public int Bar' + '\n' +
                    '    {' + '\n' +
                    '        get { return bar; }' + '\n' +
                    '        set { bar = value; }' + '\n' +
                    '    }' + '\n' +
                    '}' + '\n',

                singleLineStatements2:
                    'x = _featureTurnedOn ?     0 : 1;' + '\n',

                documentationComments1:
                    'public class Foo' + '\n' +
                    '{' + '\n' +
                    '' + '\n' +
                    '    /// <summary>Public stuff about the method</summary>' + '\n' +
                    '    /// <param name=”bar”>What a neat parameter!</param>' + '\n' +
                    '    /// <devdoc>Cool internal stuff!</devdoc>' + '\n' +
                    '    ///' + '\n' +
                    '    public void MyMethod(int bar) { … }' + '\n' +
                    '}' + '\n',

                commentStyle1:
                    '// This is required for WebClient to work through the proxy' + '\n' +
                    'GlobalProxySelection.Select = new WebProxy("http://itgproxy");' + '\n' +
                    '' + '\n' +
                    '// Create object to access Internet resources' + '\n' +
                    'WebClient myClient = new WebClient();' + '\n',

                commentStyle2:
                    'public class SomethingUseful' + '\n' +
                    '{' + '\n' +
                    '    private int          itemHash;            // instance member' + '\n' +
                    '    private static bool  hasDoneSomething;    // static member' + '\n' +
                    '}' + '\n',

                naming1:
                    'int MyMethod()' + '\n',

                naming2:
                    'public int myVariable {get; private set;}' + '\n',

                polymorphicClassNaming1:
                    'class BasePlatform' + '\n' +
                    '{' + '\n' +
                    '}' + '\n' +
                    '' + '\n' +
                    'class BasePlatformPSVR : BasePlatform' + '\n' +
                    '{' + '\n' +
                    '}' + '\n' +
                    '' + '\n' +
                    'class BasePlatformHTCVive : BasePlatform' + '\n' +
                    '{' + '\n' +
                    '}' + '\n' +
                    '' + '\n',

                dontUseShorthand1:
                    'object myObj = GetObject();' + '\n' +
                    'ProcessObject(myObj);' + '\n',

                dontUseShorthand2:
                    'ProcessObject(GetObject());' + '\n',

                dontUseShorthand3:
                    'Initialise' + '\n' +
                    '    (' + '\n' +
                    '    () =>' + '\n' +
                    '    {' + '\n' +
                    '        FnA' + '\n' +
                    '        (' + '\n' +
                    '        () =>' + '\n' +
                    '        {' + '\n' +
                    '            FnB' + '\n' +
                    '            (' + '\n' +
                    '            ()=>' + '\n' +
                    '            {' + '\n' +
                    '                FnC' + '\n' +
                    '                (' + '\n' +
                    '                ()=>' + '\n' +
                    '                {' + '\n' +
                    '                    FnD();' + '\n' +
                    '                }' + '\n' +
                    '            );' + '\n' +
                    '            }' + '\n' +
                    '            );' + '\n' +
                    '        }' + '\n' +
                    '        );' + '\n' +
                    '    }' + '\n' +
                    ');' + '\n',

                cSharpIconPathAndName:
                    require("@/assets/images/c_sharp.png"),

            };
        }
    }
